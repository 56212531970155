import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Satoshi Variable, sans-serif",
    secondary: "Cubano, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#5B7116",
      light: "#EBF3D6",
      dark: "#E1EBC9",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#152E2C",
      light: "#F1F6F0",
      dark: "#EDF4DA",
    },
    tertiary: {
      main: "#F7EFD9",
      dark: "#5E2B16",
      light: "#F8FFE5",
    },
    text: {
      primary: "#121212",
      secondary: "#224220",
      content: "#000000",
      tertiary: "#681c3a",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Satoshi Variable", "Cubano", "Arial", sans-serif`,
  },
})

export default theme
